import { Avatar, Button, Card, Group, Stack, Text } from '@mantine/core';
import { memo } from 'react';

type Props = {
  name: string;
  teamPosition: string;
  handleChange: () => void;
  handleDelete: () => void;
  isDisabled?: boolean;
  displayAboutUs?: boolean;
  photo: string;
};

export const TeacherCard = memo(
  ({ name, teamPosition, handleChange, handleDelete, isDisabled, photo, displayAboutUs }: Props) => {
    return (
      <Card radius="md" style={{ border: '1px solid #EBEFFF' }} mt="xl" shadow="sm" p="xl">
        <Group position="apart">
          <Group position="left">
            <Avatar src={photo} style={{ width: '60px', height: '60px' }} radius="xl" />
            <Stack ml="xl" spacing="xs">
              <Text size="xs" color=" #757780">
                Name
              </Text>
              <Text weight={500}>{name}</Text>
            </Stack>

            <Stack style={{ maxWidth: '300px' }} spacing="xs">
              <Text size="xs" color=" #757780">
                Role
              </Text>
              <Text weight={500}>{teamPosition}</Text>
            </Stack>
          </Group>

          <Group position="right">
            {displayAboutUs && (
              <Text mr="xl" size="xs" color=" #757780">
                Show on teachers page
              </Text>
            )}
            <Stack>
              <Button loading={isDisabled} disabled={isDisabled} onClick={handleChange} variant="subtle">
                Change details
              </Button>
              <Button loading={isDisabled} disabled={isDisabled} color="red" onClick={handleDelete} variant="subtle">
                Delete teacher
              </Button>
            </Stack>
          </Group>
        </Group>
      </Card>
    );
  },
);
