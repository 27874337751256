import { Card, Group, Image, Text, Button, TypographyStylesProvider } from '@mantine/core';
import { memo } from 'react';
import { EditIcon } from '../../assets/icons/EditIcon';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import dayjs from 'dayjs';

type Props = {
  image: string;
  title: string;
  description: string;
  handleDelete: () => void;
  handleEdit: () => void;
  createDate: Date;
};

export const BlogCard = memo(({ title, image, description, handleEdit, handleDelete, createDate }: Props) => {
  return (
    <Card style={{ minHeight: '420px' }} shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section>
        <Image src={image} height={250} withPlaceholder />
      </Card.Section>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={500}>{title}</Text>
      </Group>

      <TypographyStylesProvider>
        <Text lineClamp={3} dangerouslySetInnerHTML={{ __html: description }} />
      </TypographyStylesProvider>

      <Group mt="xl" grow>
        <Button onClick={handleEdit} leftIcon={<EditIcon />} variant="light" color="blue" radius="md">
          Edit
        </Button>
        <Button onClick={handleDelete} leftIcon={<DeleteIcon />} variant="light" color="red" radius="md">
          Delete
        </Button>

        <Group position="right">
          <Text size="xs" mt="md">
            {dayjs(createDate).format('YYYY-MM-DD')}
          </Text>
        </Group>
      </Group>
    </Card>
  );
});
