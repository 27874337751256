import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import HardBreak from '@tiptap/extension-hard-break';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import { useCallback, useEffect, useRef } from 'react';
import { Input, Stack } from '@mantine/core';

interface Props {
  value: string;
  onChange: (newValue: string) => void;
  className?: string;
  label?: string;
}

export function RTE({ value, onChange, className, label }: Props) {
  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Underline,
        Link,
        Superscript,
        SubScript,
        Highlight,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
        Color,
        TextStyle
      ],
      content: value,
      onUpdate({ editor }) {
        onChange(editor.getHTML());
      },
    },
    [],
  );

  useEffect(() => {
    editor?.off('update');
    editor?.on('update', ({ editor: updatedEditor }) => {
      onChange(updatedEditor.getHTML());
    });
    return () => {
      editor && editor.off('update');
    };
  }, [editor, onChange]);

  useEffect(() => {
    if (editor?.getHTML() != value) {
      editor?.commands.setContent(value, false);
    }
  }, [value]);

  return (
    <Stack spacing={1}>
      {label && <Input.Label>{label}</Input.Label>}
      <RichTextEditor className={className} editor={editor}>
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
            <RichTextEditor.ColorPicker colors={['#416194', '#EF1630', '#0e133d']} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
    </Stack>
  );
}
