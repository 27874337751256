import axios, { AxiosError } from 'axios';
import { showNotification } from '@mantine/notifications';

type APIError = {
  details: {
    [key: string]: string[];
  };
};

export const handleServerNetworkError = (error: Error | AxiosError<APIError>) => {
  if (axios.isAxiosError(error)) {
    showNotification({
      //crutch fix during deployment, try to fix
      //@ts-ignore
      message: error.response?.data ? error.response?.data.message : 'Some error occurred',
      color: 'red',
      autoClose: 3000,
    });

    handleAPIError(error);
  } else {
    showNotification({
      message: `Native error ${error.message}`,
      color: 'red',
      autoClose: 3000,
    });
  }
};


const handleAPIError = (error: AxiosError<APIError>) => {
  const errorDetails = error.response?.data?.details;

  if (errorDetails && Object.keys(errorDetails).length > 0) {
    showNotification({
      //crutch fix during deployment, try to fix
      //@ts-ignore
      message: Object.values(errorDetails)
        .map((errors) => errors.join('\n'))
        .join('\n'),
      color: 'red',
      autoClose: 3000,
    });
  }
};

export const encodeFileInBase = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = (error) => reject(error);
  });
};
