import { RootState } from '../store';
import { IPostResponse } from './blogTypes';
import { Nullable } from '../../helpers/nullable';

const selectIsFetching = (state: RootState): boolean => state.blog.isFetching;
const selectPosts = (state: RootState): Nullable<IPostResponse[]> => state.blog.posts;
const selectPost = (state: RootState): Nullable<IPostResponse> => state.blog.post;
const selectTags = (state: RootState): any => state.blog.tags;

export const blogSelectors = {
  selectIsFetching,
  selectPosts,
  selectPost,
  selectTags,
};
